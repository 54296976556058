import Check from './Validator';
import { AppType } from '../config/Config';
import XLSX from 'xlsx';

const Bulan = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember"
]
Math.fmod = function (a,b) { return Number((a - (Math.floor(a / b) * b)).toPrecision(8)); };

const penyebut = (n) => {
    let nilai = Math.abs(n);
    let huruf = ["", "satu", "dua", "tiga", "empat", "lima", "enam", "tujuh", "delapan", "sembilan", "sepuluh", "sebelas"];
    let temp  = "";
     if (nilai < 12) {
        temp = " " + huruf[Math.floor(nilai)];
        // console.log(nilai);
    } else if (nilai < 20) {
        temp = penyebut(nilai - 10) + " belas";
    } else if (nilai < 100) {
        temp = penyebut(nilai / 10) + " puluh" + penyebut(nilai % 10);
    } else if (nilai < 200) {
        temp = " seratus" + penyebut(nilai - 100);
    } else if (nilai < 1000) {
        temp = penyebut(nilai / 100) + " ratus" + penyebut(nilai % 100);
    } else if (nilai < 2000) {
        temp = " seribu" + penyebut(nilai - 1000);
    } else if (nilai < 1000000) {
        temp = penyebut(nilai / 1000) + " ribu " + penyebut(nilai % 1000);
    } else if (nilai < 1000000000) {
        temp = penyebut(nilai / 1000000) + " juta " + penyebut(nilai % 1000000);
    } else if (nilai < 1000000000000) {
        temp = penyebut(nilai / 1000000000) + " milyar " + penyebut(Math.fmod(nilai,1000000000));
    } else if (nilai < 1000000000000000) {
        temp = penyebut(nilai / 1000000000000) + " trilyun " + penyebut(Math.fmod(nilai,1000000000000));
    }     
    return temp;
}

export const ex_terbilang = (nilai) => {
    let hasil = `${penyebut(nilai)}`.trim();
    let data = `${nilai}`.split(".");
    if(data.length > 1) {
        if (nilai < 0) {
            hasil = "minus " +  `${penyebut(data[0]).trim()}`;
        }
        hasil += " koma " + `${penyebut(data[1]).trim()}`
        return hasil
    }else{  
        if (nilai < 0) {
            hasil = "minus " +    `${penyebut(nilai).trim()}`;
        }
        return hasil + " Rupiah"
    }
}

export const convertToUTC = (str) => {
    try {
        let toUtc = new Date(str).toUTCString();
        let toIso = new Date(toUtc).toISOString();
        return toIso;
    } catch (error) {
        console.log(error)
        return {}
    }
  

}
export const convertToLocal = (str) => {
    try {
        let local = new Date(str).toLocaleString();
        let toIso = new Date(local).toISOString();
        return toIso; 
    } catch (error) {
        console.log(error)
        return {}
    }
   
}
export const NoDokumen = ({ id_invoice, dt_invoice_created }) => {
    let pr = new Date(dt_invoice_created);
    const Type = AppType();
    if(Type === "Migas"){
        return (
            <div>
                { id_invoice }/BPM/{ pr.getFullYear() }
            </div>
        )
    }
    return (
        <div>
            { id_invoice }/BPE/{ pr.getFullYear() }
            <br/>
            { id_invoice }/BLU.PPSDMKEBTKE/I/{ pr.getFullYear() }

        </div>
    )
}
export const NoDokumenPembayaran = ({ id_invoice, dt_invoice_created }) => {
    let pr = new Date(dt_invoice_created);
    const Type = AppType();
    
    if (Type === "Migas") {
        return   `${id_invoice }/BPM/${new Date().getFullYear()}`
    }
    return id_invoice + "/BPE/" + pr.getFullYear() 
}
export const getInaDate = (dt_invoice_created ) => {
    let pr = new Date(dt_invoice_created);
     let obj = {
        date: pr.getDate(),
        month: pr.getMonth(),
        year: pr.getFullYear(), 
     }
    let str = (obj['date'] >= 10) ? obj['date']  : ("0" + obj['date']);
        str += " ";
        str += Bulan[obj['month']];
        str += " ";
        str += obj['year'];
    return str
}
export const isJson = (json) => {
    try {
        return JSON.parse(json)
    } catch (error) {
        return [];
    }
}
export const toDateTime = (str_date) => {
    let pr = new Date(str_date);
    let obj = {
        date: pr.getDate(),
        month: (pr.getMonth()+1),
        year: pr.getFullYear(),
        hour: pr.getHours(),
        minute: pr.getMinutes(),
        second: pr.getSeconds()
    }
    let date =  (obj['date'] >= 10) ? obj['date']  : ("0" + obj['date'])
        date += "/"
        date += (obj['month'] >= 10) ? obj['month'] : "0" + obj['month'];
        date += "/" + obj['year'];
        date += " ";
        date += (obj['hour'] >= 10) ? obj['hour']: "0" + obj['hour'];
        date += ":";
        date += (obj['minute'] >= 10) ? obj['minute']: "0" + obj['minute'];
        date += ":";
        date += (obj['second'] >= 10) ? obj['second']: "0" + obj['second'];

    return date;
}
export const strSpecialRemove = (str) => {
    if(str == undefined || str == null){
        return ""
    }else{
        return `${str}`.replace(/[^\w\s^(^)^&^-]/gi, '')
    }
}
export const pembilang = ({nilai}) => {
    if(nilai === 1){
        return "Pertama"
    }
    nilai = Math.abs(nilai);
    var simpanNilaiBagi=0;
    var huruf = ["", "Satu", "Dua", "Tiga", "Empat", "Lima", "Enam", "Tujuh", "Delapan", "Sembilan", "Sepuluh", "Sebelas"];
    var temp="";
 
    if (nilai < 12) {
        temp = " "+huruf[nilai];
    }
    else if (nilai <20) {
        temp = pembilang(nilai - 10) + " Belas";
    }
    else if (nilai < 100) {
        simpanNilaiBagi = Math.floor(nilai/10);
        temp = pembilang(simpanNilaiBagi)+" Puluh"+ pembilang(nilai % 10);
    }
    else if (nilai < 200) {
        temp = " Seratus" + pembilang(nilai - 100);
    }
    else if (nilai < 1000) {
        simpanNilaiBagi = Math.floor(nilai/100);
        temp = pembilang(simpanNilaiBagi) + " Ratus" + pembilang(nilai % 100);
    }
     else if (nilai < 2000) {
        temp = " Seribu" + pembilang(nilai - 1000);
    }
    else if (nilai < 1000000) {
        simpanNilaiBagi = Math.floor(nilai/1000);
        temp = pembilang(simpanNilaiBagi) + " Ribu" + pembilang(nilai % 1000);
    } 
    else if (nilai < 1000000000) {
        simpanNilaiBagi = Math.floor(nilai/1000000);
        temp =pembilang(simpanNilaiBagi) + " Juta" + pembilang(nilai % 1000000);
    } 
    else if (nilai < 1000000000000) {
        simpanNilaiBagi = Math.floor(nilai/1000000000);
        temp = pembilang(simpanNilaiBagi) + " Miliar" + pembilang(nilai % 1000000000);
    } 
    else if (nilai < 1000000000000000) {
        simpanNilaiBagi = Math.floor(nilai/1000000000000);
        temp = pembilang(nilai/1000000000000) + " Triliun" + pembilang(nilai % 1000000000000);
    }
 
    return "ke" + (temp).trim().toLowerCase();

    
}
export const getBidang = () =>{ 
    let user = JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN"));
    let user_info = JSON.parse(user.user_info);
    return user_info.bidang;
}
export const getParentLevel = (data) => {
        // root.2020.80.29
        let kodeParent = [];
        for(let d of data){
            const target = d.kode_layanan.split(".");
            const panjang = target.length;
            const isUpperThenTarget  = kodeParent.findIndex((x) => {
                let meMax = 0;
                let myKode = x.split(".");
                // menghitung benar berapa
                for(let m of myKode){
                    if (m === target[meMax]) {
                      meMax++;  
                    }
                }

                if (meMax === panjang-1) {
                 //   console.log("benar", myKode.length, panjang, meMax, panjang < myKode.length)
                    return myKode.length < panjang
                }else{
                    return false;
                }
            }) 
            if(isUpperThenTarget === -1){
                kodeParent.push(d.kode_layanan)
            }
        }
        return kodeParent;
}
export const urutkanLayanan = (data) => {
   /*
    let byLevel = {};
    //// console.log(dt)
    const data = dt.map((x, i) => {
        const lvl = x.kode_layanan.split(".").length;
        let getCurrentLevel = byLevel[lvl];
            x.index = i

        if (getCurrentLevel) {
            byLevel[lvl].push({ ...x, checked: false, expand:false, showing: false, padding_rem: lvl });
        }else{
            byLevel[lvl] = [{  ...x, checked: false, expand:false, showing: false, padding_rem: lvl  }];
        }
        return x
    })
    const levelArr =Object.getOwnPropertyNames(byLevel).map((x) => parseInt(x));
    const levelTerendah = Math.min(...levelArr);
    let finalData = [];
    function cariChildKode(kode){
        const kodeArr = kode.split(".");
        const maxIsMyChild = kodeArr.length;
        for(let i in byLevel){
            if (parseInt(i) === levelTerendah) {
                continue;
            }
            for(let d of byLevel[i]){
                const myCode = d.kode_layanan.split(".");
                let benar = 0;
                for (let k = 0; k < maxIsMyChild; k++) {
                    if(myCode[k] === kodeArr[k]){
                        // lanjut
                        benar++;
                    }else{
                        break;
                    }
                }
                if (maxIsMyChild === benar && myCode.length !== kodeArr.length) {
                    finalData.push(d);   
         //   cariChildKode(d.kode_layanan);

                }
                
              //  // console.log(kodeArr, myCode, benar)
            }
        }
    }
    // loop level arr terendah
    for(let d of byLevel[levelTerendah]){
        d.showing = true
        finalData.push(d);
       // // console.log(d)
        cariChildKode(d.kode_layanan)
    }
  //// console.log(finalData)
    return finalData;

    */
    
	var level   = [];
    for(let d of data){
        let myLevel = d.kode_layanan.split(".").length;
        d.level = myLevel;
        
        if (level.findIndex((d) =>  d == myLevel ) == -1) {
            level.push(myLevel)
        }
        
    }
    // level.sort(function(a, b) {
    //     return a - b;
    // });
  //  // console.log(level);

    // temp data result
			let res_object = [];
			// groub by level
	        let perLevel = {};
			// grouping
                let padRem = 1;
                for(let l of level){
                    let cariLevel  = data.filter((v)=>{
                        return v.level == l
                    }).map((x) => { return { checked: false, expand:false, showing: false, padding_rem: padRem,...x } })
					
                    if(cariLevel.length > 0) {
                        perLevel[l] = cariLevel;
                    }
                    padRem ++;
                }
           // // console.log(perLevel)
			// klaster function
				function klaster(i,kdAnggaran) {
					// cek apakah level exist
                    if (perLevel[i]) { 
						// loop data cari by kode -1
                        for(let k of perLevel[i]){
							// pecah kode ke array
                            let kode = k.kode_layanan.split(".");
							// hapus yang paling belakang
                            kode.splice(kode.length-1,1);
							// join target
							let join_target = kode.join(".");
							// join dan bandingkan dengan parameter kode
                            if (join_target == kdAnggaran) {
								// if(k.kode == "424113.03.01.01.01"){
								// 	// console.log("Target", join_target);
								// 	// console.log("Target", kdAnggaran);
                               // k.padding_rem = i;
								// }
								// push jika dia memang child
                                res_object.push(k);
								// cari lagi dibawahnya
                                klaster(i+1,k.kode_layanan); 
                            }
                        }
                    }
                }
                // get pertama
				let newRoot 	= Object.getOwnPropertyNames(perLevel);
				// jika root tidak diketahui level nya
				if (newRoot.length == 0) {
					return []
				}
              //  // console.log(perLevel[newRoot[0]])
				// loop yang akan jadi root
				for(let root of perLevel[newRoot[0]]){
                    root.expand = false;
                    root.showing = true;
					// push dulu ke object
					res_object.push(root);
                   // root.padding_rem = 1;
					// cari child (level, kode)s
                    // if (perLevel[newRoot[1]]) {
	    				klaster(root.level+1, root.kode_layanan)
                    // }
				}
				// balikan
				return res_object;
                
}
export const getValue  = (arr) => {
    let form = {}
    for(let a of arr){
        const get = document.getElementById(a);
        if (get) {
            form[a] = get.value;
        }else{
            console.warn(a + " is not found")
        }
    }
    return form;
}
export const onlyNumber = (e) => {
    // console.log(e.keyCode)
}

export const getValueClass  = (arr) => {
    let form = {}
    for(let a of arr){
        form[a] = document.getElementsByClassName(a).value;
    }
    return form;
}
export const setToNull = (arr) => {

    for(let a of arr){
       document.getElementById(a).value = "";
    }
}
export const numberFormatReverse = (string, float=false) => {
  let toInt =  NaN;
  let comma = NaN;
  if (float) {
    let comaNumber = string.split(".")
        comma = comaNumber[comaNumber.length-1];
    let primaryNumber = string.split(".")
        toInt = primaryNumber.slice(0, primaryNumber.length-1).join("");
  }else{
    let primaryNumber = string.replaceAll(".","").replaceAll(",","")
      toInt = parseInt(primaryNumber);
  }
//   console.log(toInt)
  if (isNaN(toInt)) {
      return "0";
  } else {
      let pars = new Intl.NumberFormat('en-CA',{style:'decimal'}).format(toInt).replaceAll(",",".");
      if(float){
      
          if(!isNaN(comma)){
              return pars + "," + comma
          }else{
              return pars
          }
      }
      return pars
  }
}
export const numberFormat = (string, float=false) => {
    let toInt = (float) ? parseInt(`${string}`.split(".")[0].replaceAll(",","")) : parseInt(string.replaceAll(".", "").replaceAll(",", ""));
    if (isNaN(toInt)) {
        return "0";
    } else {
        let pars = new Intl.NumberFormat('en-CA',{style:'decimal'}).format(toInt);
        if(float){
           const getAfterComma =  `${string}`.split(".")

            if(getAfterComma.length > 1){
                return pars + "." + getAfterComma[1]
            }else{
                return pars
            }
        }
        return pars
    }
}
// props => { attr: { username: ['required', 'isString'], values:{ username: "", password:'' } } }
export const checkType = (props) => {
    let response = []
    let status   = true;
    for(let d in props.attr){
        if ((props.values[d] == "" || !props.values[d]) && props.attr[d].required) {
            let obj = {};
            obj[d]  = "Required";
            response.push(obj)
            status = false;
        }

        if(props['attr']['checkv'] && props.values[d] != ""){
            let obj = {};
            obj[d] = Check(props.values[d], props['attr']['checkv']);

            if (!obj[d]) {
                status = false;
            }
            response.push(obj);
        }
    }
    
    return {
        status: status,
        param: response
    }
} 
export const ucWords = (str) => {
    return str.toLowerCase().replace(/(?<= )[^\s]|^./g, a => a.toUpperCase());
}
export const reverseDate = (str) => {
    if (str === undefined) {
        return ""
    }
    return str.split("-").reverse().join("-")
}
export const listBulan  =   ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober","November","Desember"];

export const getLabelsMonth = (tgl_awal, tgl_akhir) => {
  const bulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober","November","Desember"];
  const tgl_1 = tgl_awal.split("-");
  const tgl_2 = tgl_akhir.split("-");
  const mulaiBulan = parseInt(tgl_1[1]);
  const selesaiBulan = parseInt(tgl_2[1]);
  const mulaiTahun = parseInt(tgl_1[0]);
  const selesaiTahun = parseInt(tgl_2[0]);
  let finalData = [];
  let b = mulaiBulan;
  for(let i = mulaiTahun; i <= selesaiTahun; i++){
    for(b; b <= 12; b++){
     finalData.push(`${bulan[b-1]} ${i}`);
      if (`${i}-${b}` === `${selesaiTahun}-${selesaiBulan}`) {
          break;
      }
    }
    if (b > 12) {
        b = 1;
    }
  }
  return finalData
}
export const VaParse = (data) => {
    const find = data.split(",").map((x) => {
        if (x.indexOf("no_va") >= 0) {
          let splVa = x.split(":")
            splVa[1] = `"${splVa[1]}"`
          return splVa.join(":")
        }
        return x
      })
      return JSON.parse(find.join(","));
}

export const getCred = () => {
    let role = localStorage.getItem("PENERIMAAN_ACTIVE_ROLE");
    const c = { withCredentials: true, headers: { active_role: role, Bidang: getBidang()  } }
   
    return c
}

export const invoiceExpired = (created, info, tipe_transaksi, localTime) => {
    if (info.id_app == "1" || info.id_app == undefined || info.id_app == null) {
        return false
    }
    let status = false
    // pembuatan + 12 jam
     const dtCreated = new Date(created).getTime() + 43200000;
    //const dtCreated = new Date(created).getTime() + 7200000;

    const timeNow   = new Date(localTime).getTime() ;

    status = (dtCreated < timeNow);
   
    if (tipe_transaksi != "perorangan") {
        status = false;
    }
    
    if (info.tipe_pembayaran != "VA") {
        status = false;
    }
    return status
}
export const saveToExcel = (title, data) => {
    var ws = XLSX.utils.json_to_sheet(data);
                /* add to workbook */
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, title);
                let name     =  title;
                /* generate an XLSX file */
                XLSX.writeFile(wb, name + ".xlsx");
}

export const randomColor = () => {
    const bg = ["#DC2626", "#EA580C", "#D97706", "#65A30D", "#EAB308", "#16A34A", "#059669", "#0D9488", "#2563EB", "#9333EA"]
    const a  = Math.floor(Math.random() * (bg.length-1)) + 0
    return bg[a]
}

export const getTtdKabag = ({ tanggal }) => {
    
    const time_doc = new Date(tanggal)
    
    if(time_doc >= new Date("2024-04-01")){
        return {
            nama: 'Rr. Rizki Amalia Nurhayati, S.H., M.H.',
            nip: '19810126 200604 2 001'
        }
    }else{
        return {
            nama: 'Raden Moehammad Noermansyah, M.M.',
            nip: '19771202 201012 1 001'
        }
    }
}